import React from 'react';
import { Link, useLocation } from 'react-router-dom';
function Header() {
    const location = useLocation();

    const handleLogout = () => {
        localStorage.setItem('sessionToken', '');
        window.location.href = '/';
    };
    return (
        <>
            {/* <nav class="navbar navbar-expand-lg navbar-dark ">
                <a class="navbar-brand" href="#">Navbar</a>
                <button class="navbar-toggler d-none" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">

                    <ul class="navbar-nav ml-auto d-none">
                        <li class="nav-item active">
                            <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Link</a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Dropdown
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a class="dropdown-item" href="#">Action</a>
                                <a class="dropdown-item" href="#">Another action</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#">Something else here</a>
                            </div>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link disabled" href="#">Disabled</a>
                        </li>
                    </ul>
                </div>
            </nav> */}
            <div class="nav mobile-nav">
                <Link to="/" className={location.pathname === '/' ? 'nav__link nav__link--active' : 'nav__link'}>
                    <i className="align-middle material-icons">home</i>
                    <span>Dashboard</span>
                </Link>
                <Link to="/smartcard" className={location.pathname === '/smartcard' ? 'nav__link nav__link--active' : 'nav__link'}>
                    <i className="align-middle material-icons">badge</i>
                    <span>Your card</span>
                </Link>
                <Link to="/settings" className={location.pathname === '/settings' ? 'nav__link nav__link--active' : 'nav__link'}>
                    <i className="align-middle material-icons">settings</i>
                    <span>Settings</span>
                </Link>
                <a href="#" onClick={handleLogout} className='nav__link'>
                    <i className="align-middle material-icons">logout</i>
                    <span>Logout</span>
                </a>
            </div>
        </>
    )
};

export default Header;