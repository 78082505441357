import React, { useState } from 'react';
import { auth } from '../firebaseConfig';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import '../Login.css';
import Spinner from 'react-bootstrap/Spinner';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { faL } from '@fortawesome/free-solid-svg-icons';
import Alert from 'react-bootstrap/Alert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../logo.png';

function Login({ setIsLoggedIn, fetchUserData }) {
    const [phone, setPhone] = useState('');
    const [otp, setOtp] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [confirmationResult, setConfirmationResult] = useState(null);
    const [username, setUsername] = useState('');
    const [gender, setGender] = useState('');
    const [phoneNumberExist, setPhoneNumberExist] = useState(true);
    const [loading, setLoading] = useState(false);

    const handleSendOtp = () => {
        setLoading(true);
        if (phoneNumberExist) {
            const url = `https://api.connectbynfc.com/api/appapi/check_phone_number/${phone}`;
            const uname = 'connectbynfc';
            const password = 'Adsthrive@123';
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Authorization': 'Basic ' + btoa(uname + ':' + password),
                    'Content-Type': 'application/json'
                },
            };

            fetch(url, requestOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    // Check the response to determine if the phone number exists
                    if (data.exists) {
                        // Phone number exists, proceed with sending OTP
                        try {
                            const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                                size: 'invisible',
                                callback: (response) => {
                                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                                    // console.log(response);
                                },
                                'expired-callback': () => {
                                    // Response expired. Ask user to solve reCAPTCHA again.
                                }
                            });

                            const phoneNumber = `+${phone}`;
                            signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier)
                                .then(confirmationResult => {
                                    console.log('OTP sent successfully');
                                    console.log(confirmationResult);
                                    setConfirmationResult(confirmationResult);
                                    setIsOtpSent(true);
                                }).catch(error => {
                                    console.error('SMS not sent', error);
                                });
                        } catch (error) {
                            console.error('Error setting up reCAPTCHA', error);
                        }
                    } else {
                        console.log('Phone number does not exist');
                        setPhoneNumberExist(false);
                    }
                })
                .catch(error => {
                    console.error('Error checking phone number existence', error);
                    // Handle error, e.g., show error message to the user
                }).finally(() => {
                    setLoading(false);
                });
        } else {
            // First, check if the phone number exists using the API
            const url = `https://api.connectbynfc.com/api/appapi/register_user`;
            const uname = 'connectbynfc';
            const password = 'Adsthrive@123';
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': 'Basic ' + btoa(uname + ':' + password),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    phone_number: phone,
                    user_name: username,
                    gender: gender
                })
            };

            fetch(url, requestOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    // Check the response to determine if the phone number exists
                    if (data.message) {
                        // Phone number exists, proceed with sending OTP
                        try {
                            const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                                size: 'invisible',
                                callback: (response) => {
                                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                                    // console.log(response);
                                },
                                'expired-callback': () => {
                                    // Response expired. Ask user to solve reCAPTCHA again.
                                }
                            });

                            const phoneNumber = `+${phone}`;
                            signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier)
                                .then(confirmationResult => {
                                    console.log('OTP sent successfully');
                                    console.log(confirmationResult);
                                    setConfirmationResult(confirmationResult);
                                    setIsOtpSent(true);
                                }).catch(error => {
                                    console.error('SMS not sent', error);
                                });
                        } catch (error) {
                            console.error('Error setting up reCAPTCHA', error);
                        }
                    } else {
                        console.log('Phone number does not exist');
                    }
                })
                .catch(error => {
                    console.error('Error checking phone number existence', error);
                    // Handle error, e.g., show error message to the user
                }).finally(() => {
                    setLoading(false);
                });
        }
    };

    const handleVerifyOtp = () => {
        setLoading(true);
        confirmationResult.confirm(otp)
            .then(result => {
                toast.success('Login successful!', { position: "top-center" });
                localStorage.setItem('sessionToken', phone);
                setIsLoggedIn(true);
                fetchUserData(phone);
            }).catch(error => {
                console.error('Invalid OTP', error);
            }).finally(() => {
                setLoading(false);
            });
    };
    
    return (
        <div>
            <div style={{justifyContent: 'center',width: '100%',display: 'flex',position: 'absolute',top: '40px'}}>
                <img src={logo} className="App-logo" alt="logo"/>
            </div>
            <div className="login">
            <ToastContainer position="top-center" autoClose={3000} />
            <div className="box">
                <div className="form">
                    <h2>Login</h2>
                    <div id="recaptcha-container"></div>
                    {!isOtpSent && !phoneNumberExist && (
                        <>
                            <div className="inputBox">
                                <input
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required="required"
                                />
                                <span>Username</span>
                                <i></i>
                            </div>
                            <br />
                            <div className='formInputBox'>
                                <span className='input-label'>Gender</span>
                                <select
                                    value={gender}
                                    onChange={(e) => setGender(e.target.value)}
                                >
                                    <option value=''>Select gender</option>
                                    <option value='male'>Male</option>
                                    <option value='female'>Female</option>
                                    <option value='other'>Other</option>
                                </select>
                            </div>
                        </>
                    )}
                    {!isOtpSent ? (
                        <>
                            <div className='formInputBox'>
                                <span className='input-label'>Phone Number</span>
                                <PhoneInput
                                    country={'in'}
                                    value={phone}
                                    onChange={setPhone}
                                    placeholder="Enter phone number"
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                        autoFocus: true
                                    }}
                                    containerClass="phone-input-container"
                                    inputClass="phone-input"
                                />
                                <i></i>
                            </div>

                            {loading ? (
                                <span className="spinnerbtn">
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </span>
                            ) : (
                                <span onClick={handleSendOtp}>
                                    <input type="submit" value="Send OTP" />
                                </span>
                            )}
                        </>
                    ) : (
                        <>
                            <div className="inputBox">
                                <input
                                    type="text"
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                    required="required"
                                />
                                <span>OTP</span>
                                <i></i>
                            </div>
                            {loading ? (
                                <span className="spinnerbtn">
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </span>
                            ) : (
                                <span onClick={handleVerifyOtp}><input type="submit" value="Verify OTP" /></span>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
        </div>
    );
}

export default Login;
