import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Sidebar() {
    const location = useLocation();

    const handleLogout = () => {
        localStorage.setItem('sessionToken', '');
        window.location.href = '/';
    };

    return (
        <div id="sidebar-wrapper">
            <ul className="sidebar-nav">
                <li className={location.pathname === '/' ? 'active' : ''}>
                    <Link to="/">
                        <i className="align-middle material-icons">home</i>
                        <span>Dashboard</span>
                    </Link>
                </li>
                <li className={location.pathname === '/smartcard' ? 'active' : ''}>
                    <Link to="/smartcard">
                        <i className="align-middle material-icons">badge</i>
                        <span>Your card</span>
                    </Link>
                </li>
                <li className={location.pathname === '/settings' ? 'active' : ''}>
                    <Link to="/settings">
                        <i className="align-middle material-icons">settings</i>
                        <span>Settings</span>
                    </Link>
                </li>
                <li>
                    <a href="#" onClick={handleLogout}>
                        <i className="align-middle material-icons">logout</i>
                        <span>Logout</span>
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default Sidebar;