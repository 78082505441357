import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Header from './includes/Header';
import Sidebar from './includes/Sidebar';
import Home from './screens/Home';
import Login from './screens/Login';
import Scard from './screens/Scard';
import ScardApp from './screens/ScardApp';
import Settings from './screens/Settings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const sessionToken = localStorage.getItem('sessionToken');
    console.log(sessionToken);
    if (!isLoggedIn && sessionToken) {
      fetchUserData(sessionToken);
    } else {
      setLoading(false);
    }
  }, [isLoggedIn]);

  const fetchUserData = (sessionToken) => {
    const url = `https://api.connectbynfc.com/api/appapi/fetch_user_data/${sessionToken}`;
    const uname = 'connectbynfc';
    const password = 'Adsthrive@123';
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': 'Basic ' + btoa(uname + ':' + password),
        'Content-Type': 'application/json'
      },
    };

    fetch(url, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('User data:', data.data);
        setUserData(data.data);
        setIsLoggedIn(true);
      })
      .catch(error => {
        console.error('Error fetching user data', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const shouldHideHeaderAndSidebar = location.pathname.startsWith('/editprofile/');

  return (
    <div>
      {loading ? (
        <div className='flex pageloader'>
          <div>
            <div className="nb-spinner"></div>
          </div>
        </div>
      ) : (
        isLoggedIn && userData ? (
          <>
            {!shouldHideHeaderAndSidebar && <Header />}
            <div id="wrapper">
              {!shouldHideHeaderAndSidebar && <Sidebar />}
              <div id="page-content-wrapper">
                {!shouldHideHeaderAndSidebar && <a href="#menu-toggle" id="menu-toggle" className="btn"><FontAwesomeIcon icon={faCaretRight} /></a>}
                <Routes>
                  <Route path="/" element={<Home userData={userData} />} />
                  <Route path="/smartcard" element={<Scard userData={userData} />} />
                  <Route path="/settings" element={<Settings userData={userData} />} />
                  <Route path='/editprofile/:id' element={<ScardApp />} />
                </Routes>
              </div>
            </div>
          </>
        ) : (
          !shouldHideHeaderAndSidebar ? (
            <Routes>
              <Route path="/*" element={<Login setIsLoggedIn={setIsLoggedIn} fetchUserData={fetchUserData} />} />
            </Routes>
          ) : (
            <div id="wrapper">
              <div id="page-content-wrapper">
                <Routes>
                  <Route path='/editprofile/:id' element={<ScardApp />} />
                </Routes>
              </div>
            </div >
          )
        )
      )}
    </div >
  );
}

export default App; 