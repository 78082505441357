import React, { useEffect, useState } from 'react';
import { Line, Bar, Pie, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, ArcElement } from 'chart.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    ArcElement
);

function Home({ userData }) {
    const [loading, setLoading] = useState(false);
    const [HeadShotCroppedImageUrl, setHeadShotCroppedImageUrl] = useState(null);
    const [prefix, setPrefix] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [gender, setGender] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [businessAddress, setBusinessAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [country, setCountry] = useState('');
    const [businessDescription, setBusinessDescription] = useState('');
    const [brandLogo, setBrandLogo] = useState(null);
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);
    const [pageTrackingData, setPageTrackingData] = useState([]);

    useEffect(() => {
        if (userData) {
            setLoading(true);
            const url = `https://api.connectbynfc.com/api/appapi/fetch_scard/${userData?.uid}`;
            const uname = 'connectbynfc';
            const password = 'Adsthrive@123';
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Authorization': 'Basic ' + btoa(uname + ':' + password),
                    'Content-Type': 'application/json'
                },
            };

            fetch(url, requestOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log('User data:', data.data);
                    if (data.data.cover_image_url !== '') {
                        setCroppedImageUrl(`https://api.connectbynfc.com/uploads/cover_images/${data.data.cover_image_url}`);
                    }
                    if (data.data.brand_image_url !== '') {
                        setBrandLogo(`https://api.connectbynfc.com/uploads/brand_images/${data.data.brand_image_url}`);
                    }
                    if (data.data.profile_picture !== '') {
                        setHeadShotCroppedImageUrl(`https://api.connectbynfc.com/uploads/profile_picture/${data.data.profile_picture}`);
                    }
                    setPrefix(data.data.prefix);
                    setFirstName(data.data.firstname);
                    setLastName(data.data.lasename);
                    setGender(data.data.gender);
                    setJobTitle(data.data.job_title);
                    setBusinessName(data.data.business_name);
                    setBusinessAddress(data.data.business_address);
                    setCity(data.data.business_city);
                    setPostalCode(data.data.business_postcode);
                    setCountry(data.data.business_country);
                    setState(data.data.business_state);
                    setBusinessDescription(data.data.business_description);
                    const pageTrackingUrl = `https://api.connectbynfc.com/api/appapi/page_tracking/${data.data?.smartcard_id}`;
                    const pageTrackingRequestOptions = {
                        method: 'GET',
                        headers: {
                            Authorization: 'Basic ' + btoa(uname + ':' + password),
                            'Content-Type': 'application/json',
                        },
                    };
                    fetch(pageTrackingUrl, pageTrackingRequestOptions)
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                            return response.json();
                        })
                        .then(pageTrackingData => {
                            console.log('Page Tracking data:', pageTrackingData);
                            setPageTrackingData(pageTrackingData.data);
                        })
                        .catch(error => {
                            console.error('Error fetching page tracking data', error);
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                })
                .catch(error => {
                    console.error('Error fetching user data', error);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }, [userData]);

    const lineData = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'Line Dataset',
                data: [65, 59, 80, 81, 56, 55, 40],
                fill: false,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
            },
        ],
    };

    const barData = {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
            {
                label: '# of Votes',
                data: [12, 19, 3, 5, 2, 3],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const doughnutData = {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
            {
                label: '# of Votes',
                data: [12, 19, 3, 5, 2, 3],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const pieData = {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
            {
                label: '# of Votes',
                data: [12, 19, 3, 5, 2, 3],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const profileTapsData = {
        labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
        datasets: [
            {
                label: 'Profile Taps',
                data: [10, 20, 10, 30],
                fill: true,
                backgroundColor: 'rgba(153, 102, 255, 0.4)',
                borderColor: 'rgba(153, 102, 255, 1)',
                tension: 0.4,
                pointRadius: 0,
                pointHoverRadius: 0,
            },
        ],
    };

    const linkTapsData = {
        labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
        datasets: [
            {
                label: 'Link Taps',
                data: [15, 25, 35, 45],
                fill: false,
                backgroundColor: 'rgba(255, 159, 64, 0.4)',
                borderColor: 'rgba(255, 159, 64, 1)',
                tension: 0.4,
                pointRadius: 0,
                pointHoverRadius: 0,
            },
        ],
    };

    const newConnectionsData = {
        labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
        datasets: [
            {
                label: 'New Connections',
                data: [5, 10, 15, 20],
                fill: false,
                backgroundColor: 'rgba(75, 192, 192, 0.4)',
                borderColor: 'rgba(75, 192, 192, 1)',
                tension: 0.4,
                pointRadius: 0,
                pointHoverRadius: 0,
            },
        ],
    };

    const tapThroughRateData = {
        labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
        datasets: [
            {
                label: 'Tap Through Rate',
                data: [2, 4, 6, 8],
                fill: false,
                backgroundColor: 'rgba(255, 99, 132, 0.4)',
                borderColor: 'rgba(255, 99, 132, 1)',
                tension: 0.4,
                pointRadius: 0,
                pointHoverRadius: 0,
            },
        ],
    };

    const options = {
        scales: {
            x: {
                display: false,
                grid: {
                    display: false,
                },
            },
            y: {
                display: false,
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <Container fluid>
            {loading && (
                <div className='flex pageloader'>
                    <div>
                        <div className="nb-spinner"></div>
                    </div>
                </div>
            )}
            <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                    <div id="Theme1">
                        <header className='homeHeader'>
                            <div className="headerImgC">
                                {brandLogo && (
                                    <img id="logo" src={brandLogo} alt="Logo" style={{ margin: '3rem 0px' }} />
                                )}
                                {croppedImageUrl && (
                                    <img id="cover" src={croppedImageUrl} alt="Background Pattern" />
                                )}
                            </div>
                        </header>
                        <main style={{ marginTop: croppedImageUrl || brandLogo ? '0rem' : '5rem' }}>
                            <div id="profile" className='myprofile'>
                                {HeadShotCroppedImageUrl && (
                                    <img src={HeadShotCroppedImageUrl} alt="Photo" />
                                )}
                                <div id="info">
                                    <a href={`https://app.connectbynfc.com/profile/${userData?.url_safix}`} target='_blank'><p className="name textw">{prefix} {firstName} {lastName}</p></a>
                                    {gender !== '' && (<p className="pronouns textw">{gender}</p>)}
                                    <p className="jobtitle textw">{jobTitle}</p>
                                    <p className="jobtitle textw">{businessName}</p>
                                </div>
                            </div>
                            <p className="bizaddr textw">{businessAddress !== '' && `${businessAddress},`} {city !== '' && `${city},`} {state} {postalCode} {country}</p>
                        </main>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                    <h3 className='mb-3'>Performance</h3>
                    <Row>
                        <Col lg={6} md={6} sm={6} xs={6} className='pb-3'>
                            <div className="card">
                                <div className="card-body">
                                    <p className='graphcounts'>{pageTrackingData.length > 0 ? pageTrackingData[0].profile_taps : 0}</p>
                                    <Line data={{
                                        labels: pageTrackingData.map(item => item.created_at),
                                        datasets: [
                                            {
                                                label: 'Profile Taps',
                                                data: pageTrackingData.map(item => parseInt(item.profile_taps)),
                                                fill: true,
                                                backgroundColor: 'rgba(153, 102, 255, 0.4)',
                                                borderColor: 'rgba(153, 102, 255, 1)',
                                                tension: 0.4,
                                                pointRadius: 0,
                                                pointHoverRadius: 0,
                                            },
                                        ]
                                    }} options={options} height={30} />
                                    <p className='graphtitle'><i className="align-middle material-icons">visibility</i> Views</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className='pb-3'>
                            <div className="card">
                                <div className="card-body">
                                    <p className='graphcounts'>{pageTrackingData.length > 0 ? pageTrackingData[0].link_taps : 0}</p>
                                    <Line data={{
                                        labels: pageTrackingData.map(item => item.created_at),
                                        datasets: [
                                            {
                                                label: 'Link Taps',
                                                data: pageTrackingData.map(item => parseInt(item.link_taps)),
                                                fill: false,
                                                backgroundColor: 'rgba(255, 159, 64, 0.4)',
                                                borderColor: 'rgba(255, 159, 64, 1)',
                                                tension: 0.4,
                                                pointRadius: 0,
                                                pointHoverRadius: 0,
                                            },
                                        ]
                                    }} options={options} height={30} />
                                    <p className='graphtitle'><i className="align-middle material-icons">touch_app</i> Link Taps</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className='pb-3'>
                            <div className="card">
                                <div className="card-body">
                                    <p className='graphcounts'>0</p>
                                    <Line data={tapThroughRateData} options={options} height={30} />
                                    <p className='graphtitle'><i className="align-middle material-icons">ads_click</i> Tap Through Rate</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className='pb-3'>
                            <div className="card">
                                <div className="card-body">
                                    <p className='graphcounts'>0</p>
                                    <Line data={newConnectionsData} options={options} height={30} />
                                    <p className='graphtitle'><i className="align-middle material-icons">group</i> New Connections</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col lg={3} md={3} sm={12} xs={12} className='mb-3'>
                    <div className="card">
                        <div className="card-body phonediv">
                            <div className='phonedivone'>
                                <span className='callicon'>
                                    <i className="align-middle material-icons">call</i>
                                </span>
                                <span className='icontext'>Call</span>
                            </div>
                            <div className='phonedivtwo'>
                                <p> {pageTrackingData.length > 0 ? pageTrackingData[0].phone_click : 0} <span>Taps</span></p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg={3} md={3} sm={12} xs={12} className='mb-3'>
                    <div className="card">
                        <div className="card-body phonediv">
                            <div className='phonedivone'>
                                <span className='whatsappicon'>
                                    <svg style={{ width: '20px' }} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" fill="#fff">
                                        <path d="M2.841 16.664A10.987 10.987 0 014.776 3.725c4.297-4.297 11.273-4.297 15.57 0 4.297 4.297 4.297 11.273 0 15.57-3.522 3.522-8.845 4.157-13.018 1.904l-4.142.784a1.002 1.002 0 01-1.167-1.178l.822-4.141zm1.428 3.078l3.045-.576c.238-.045.483-.002.692.12 3.452 2.024 7.966 1.555 10.926-1.405 3.516-3.516 3.516-9.226 0-12.742s-9.226-3.516-12.742 0a8.992 8.992 0 00-1.432 10.86 1 1 0 01.116.696l-.605 3.047z"></path>
                                        <path d="M17.5 14.002v1.512a1.01 1.01 0 01-1.006 1.006c-.03 0-.06-.002-.09-.004a9.948 9.948 0 01-4.34-1.544 9.811 9.811 0 01-3.016-3.017 9.944 9.944 0 01-1.544-4.359A1.01 1.01 0 018.505 6.5h1.508a1.01 1.01 0 011.006.865c.063.483.181.957.352 1.413.137.367.049.782-.227 1.061l-.638.638a8.039 8.039 0 003.017 3.017l.638-.639a1.008 1.008 0 011.061-.226c.456.17.93.288 1.413.352.503.071.877.513.865 1.021z"
                                            fill-rule="nonzero"></path>
                                    </svg>
                                </span>
                                <span className='icontext'>WhatsApp</span>
                            </div>
                            <div className='phonedivtwo'>
                                <p>{pageTrackingData.length > 0 ? pageTrackingData[0].whatsapp_clisk : 0} <span>Taps</span></p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg={3} md={3} sm={12} xs={12} className='mb-3'>
                    <div className="card">
                        <div className="card-body phonediv">
                            <div className='phonedivone'>
                                <span className='mailicon'>
                                    <i className="align-middle material-icons">mail</i>
                                </span>
                                <span className='icontext'>Email</span>
                            </div>
                            <div className='phonedivtwo'>
                                <p>{pageTrackingData.length > 0 ? pageTrackingData[0].phone_click : 0} <span>Taps</span></p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg={3} md={3} sm={0} xs={0} className='mb-3'>
                    <div className="card">
                        <div className="card-body phonediv">
                            <div className='phonedivone'>
                                <span className='blankicon'>
                                </span>
                                <span className='icontext'></span>
                            </div>
                            <div className='phonedivtwo'>
                                <p> <span></span></p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <br />
        </Container>
    );
}

export default Home;