// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyC3OW6Urt2ld-zpiL3tvsgxxByPYj-ozUA",
    authDomain: "smartcard-6f4fd.firebaseapp.com",
    projectId: "smartcard-6f4fd",
    storageBucket: "smartcard-6f4fd.appspot.com",
    messagingSenderId: "92633699879",
    appId: "1:92633699879:web:ebdb140333e91c7fdf2002",
    measurementId: "G-ZWBJELQ7BH"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };